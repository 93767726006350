// App.js

import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { Connection, clusterApiUrl, PublicKey } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import "./App.css";
import QRCode from "qrcode.react";
import {
  MdAdd,
  MdArrowForward,
  MdCamera,
  MdCheck,
  MdCheckBox,
  MdClose,
  MdDelete,
} from "react-icons/md";
import { Scanner as QrScanner } from "@yudiel/react-qr-scanner";
import moment from "moment";

function App() {
  const [data, setData] = useState({});
  const [postData, setPostData] = useState({
    name: "",
    type: "",
  });
  const [result, setResult] = useState(null);
  const [other, setOther] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [wallet, setWallet] = useState("");
  const [mint, setMint] = useState([]);
  const [mintData, setMintData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tempChange, setTempChange] = useState({ name: "", type: "" });
  const [page, setPage] = useState("D");
  const [tempData, setTempData] = useState({ name: "", type: "" });

  const customApiUrl =
    "https://blissful-flashy-log.solana-mainnet.quiknode.pro/ea75605bbc600e21ed5ac0e9dedaa3f7c07d2e28/"; // Replace with your actual URL
  const connection = new Connection(customApiUrl);

  const urlKey = [
    "c2aec335-12ad-4e7e-a425-22114052de00",
    "aeab4405-ce00-4b6b-817e-cfe62e303de5",
    "b710849f-e496-4b45-8932-e065b0ec0b4a",
    "58e5a6a8-7384-4482-928f-cc9440de8eca",
    "25bbd8ae-0855-41b2-a9c3-183a736b1bcc",
    "06ca8b43-ac6b-4195-81b9-81b7da18d5a9",
    "2dc7336e-338c-4cb6-9528-07e5a946abb9",
    "14c0d9f5-6397-4413-b75a-614d072f7763",
  ];
  //getting the metadata for mini and verifying
  const urlForNft = `https://api.helius.xyz/v0/tokens/metadata?api-key=${
    urlKey[Math.floor(Math.random() * urlKey.length)]
  }`;
  const getMetadata = async (mint) => {
    try {
      const { data } = await axios.post(urlForNft, { mintAccounts: mint });
      console.log(data);
      setMintData(data);
      console.log("THIS IS DATA");
    } catch {
      console.log("");
    }
  };
  const onResult = useCallback((result, error) => {
    if (!!result) {
      setData(result?.text);
    }
    if (!!error) {
      console.info(error);
    }
  }, []);

  const connectWallet = async () => {
    try {
      if ("solana" in window) {
        const provider = window.solana;
        if (provider.isPhantom) {
          try {
            const response = await window.solana.connect();
            console.log(
              "Connected with Public Key:",
              response.publicKey.toString()
            );
            if (wallet != "") {
              setWallet("");
            } else {
              setWallet(response.publicKey.toString());
            }
            return response.publicKey;
          } catch (err) {
            // Handle the error
            console.error(err);
          }
        } else {
          console.log("Phantom wallet not found. Please install it.");
        }
      } else {
        console.log(
          "Solana object not found! You need a Solana wallet extension."
        );
      }
    } catch {
      console.log("");
    }
  };

  const fetchTokens = async (publicKey = wallet) => {
    console.log("fetching");
    // Directly use the custom RPC URL as a string
    const network =
      "https://blissful-flashy-log.solana-mainnet.quiknode.pro/ea75605bbc600e21ed5ac0e9dedaa3f7c07d2e28/";
    const connection = new Connection(network);
    let m = [];

    const accounts = await connection.getParsedTokenAccountsByOwner(
      new PublicKey(publicKey),
      { programId: TOKEN_PROGRAM_ID }
    );
    console.log(accounts.value);
    accounts.value.map(({ account }) => {
      const { mint, owner, tokenAmount } = account.data.parsed.info;
      m.push(mint);
    });
    setMint(m);
    getMetadata(m);
  };

  // Function to make GET request
  const fetchData = async () => {
    try {
      const response = await axios.get("https://admlabz.com/testdatabase/get");
      setOther(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const deleteItem = async (a) => {
    try {
      setLoading(true);
      const response = await axios
        .post("https://admlabz.com/testdatabase/delete", a)
        .then(() => {
          setLoading(false);
          console.log("completed");
          return fetchData();
        });
    } catch (error) {
      console.error("Error posting data: ", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to make POST request
  const postDataHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios
        .post("https://admlabz.com/testdatabase/post", postData)
        .then(() => {
          setPostData({
            name: "",
            type: "",
          });
          setLoading(false);
          setShowModal(false);
          return fetchData();
        });
    } catch (error) {
      console.error("Error posting data: ", error);
      setLoading(false);
      setShowModal(false);
    }
  };

  const changePostData = (type, e) => {
    let x = { ...postData };
    switch (type) {
      case "name":
        x = { ...x, name: e };
        break;
      case "type":
        x = { ...x, type: e };
        break;
    }
    setPostData(x);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  const modalPlace = (title = "Add to Database", text = "TEXT") => {
    return (
      <div
        className="fixed inset-0 bg-black bg-opacity-50  flex  items-center"
        onClick={() => setShowModal(false)}
      >
        <div
          className="bg-white p-4 md:p-8 lg:p-12  rounded-lg shadow-lg w-full ml-1 mr-1"
          onClick={handleModalClick}
        >
          <form onSubmit={postDataHandler}>
            <h2 className="text-2xl font-bold mb-4">{title}</h2>
            <div className="pb-2 w-full flex flex-col gap-2">
              <input
                className="w-full border-2 p-2 text-black"
                placeholder="Name"
                value={postData.name}
                onChange={(e) => changePostData("name", e.target.value)}
              />
              <input
                className="w-full border-2 p-2 text-black"
                placeholder="Type"
                value={postData.type}
                onChange={(e) => changePostData("type", e.target.value)}
              />

              {loading ? (
                <button
                  className="p-4 bg-slate-500 text-white w-full rounded-lg mb-2 opacity-70"
                  onClick={postDataHandler}
                >
                  Loading
                </button>
              ) : (
                <button
                  className="p-4 bg-slate-500 text-white w-full rounded-lg mb-2"
                  onClick={postDataHandler}
                  type="submit"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  };

  const addModal = () => {
    setShowModal(true);
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  const counter = useRef(0);
  useEffect(() => {
    const interval = setInterval(() => {
      let x = counter.current;
      x += 1;
      counter.current = x;
      if (x >= 30) {
        counter.current = 0;
        fetchData();
        console.log("Reload");
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [showModal]);

  useEffect(() => {
    if (wallet != "") {
      fetchTokens();
    } else {
      setMintData([]);
    }
  }, [wallet]);

  const tempWallet = () => {
    let x = wallet;
    x = x.split("");
    x.length = 10;
    x = x.join("");
    if (wallet.length > 10) {
      return x + "...";
    } else {
      return x;
    }
  };

  const changeItem = async (a) => {
    console.log(a);
    console.log(tempChange);
    try {
      setLoading(true);
      const response = await axios
        .post("https://admlabz.com/testdatabase/update", tempChange)
        .then(() => {
          setLoading(false);
          console.log("completed");
          return fetchData();
        });
      console.log("done");
    } catch (error) {
      console.error("Error posting data: ", error);
    } finally {
      setLoading(false);
      counter.current = 0;
    }
  };

  const completeItem = async (a) => {
    console.log(a);

    try {
      setLoading(true);
      const response = await axios
        .post("https://admlabz.com/testdatabase/updatecomplete", a)
        .then(() => {
          setLoading(false);
          console.log("completed");
          return fetchData();
        });
      console.log("done");
    } catch (error) {
      console.error("Error posting data: ", error);
    } finally {
      setLoading(false);
      counter.current = 0;
    }
  };

  const sendLog = async (e) => {
    try {
      console.log("SENDING TO LOGGGGG");
      setLoading(true);
      const response = await axios
        .post("https://admlabz.com/testdatabase/postlog", e)
        .then(() => {
          console.log("Completed log");
          setLoading(false);
          return fetchData();
        });
    } catch (error) {
      console.error("Error posting data: ", error);
      setLoading(false);
      setShowModal(false);
    }
  };

  const checkCode = async (data) => {
    console.log("CHECKING CODE");
    console.log(data);
    console.log("END CHECK CODE");
    try {
      setLoading(true);
      // Directly await the axios call to handle the response
      const response = await axios.post(
        "https://admlabz.com/testdatabase/checkcode",
        data
      );
      // No need for a separate .then() here since we're awaiting the response
      console.log("Check Code Response: ", response.data);
      let x = response.data;
      const dateNow = moment().format("MM/DD/YYYY HHmm");
      if (response.data.result == true) {
        console.log("THIS IS TRUE");
        setResult(true);
        setTempData({
          name: response.data.name[0].name,
          type: response.data.name[0].type,
        });
        sendLog({
          date: dateNow,
          name: data.name,
          codes: data.codes,
          status: "success",
        });
      } else if (response.data.result == false) {
        setResult(false);
        sendLog({
          date: dateNow,
          name: "No Name",
          status: "not successful",
          codes: data.codes,
        });
      }
    } catch (error) {
      console.error("Error posting data: ", error);
    } finally {
      setLoading(false);
      console.log("Completed");
      fetchData(); // Assuming fetchData is synchronous or you do not need to wait for it
      counter.current = 0;
    }
  };

  const changeDaCode = async (data) => {
    console.log("CHECKING CODE");
    console.log(data);
    try {
      setLoading(true);
      // Directly await the axios call to handle the response
      const response = await axios.post(
        "https://admlabz.com/testdatabase/changecodes",
        data
      );
    } catch (error) {
      console.error("Error posting data: ", error);
    } finally {
      console.log("Completed");
      fetchData(); // Assuming fetchData is synchronous or you do not need to wait for it
      counter.current = 0;
    }
  };

  const changeName = (e, a) => {
    let id = e.id;
    let index = other.findIndex((a) => a.id == id);
    let tempOther = [...other];
    tempOther[index].name = a;
    let type = tempOther[index].type;
    setOther(tempOther);
    setTempChange({ id: id, name: a, type: type });
    counter.current = 0;
  };

  const changeType = (e, a) => {
    let id = e.id;
    let index = other.findIndex((a) => a.id == id);
    let tempOther = [...other];
    tempOther[index].type = a;
    let name = tempOther[index].name;
    setOther(tempOther);
    setTempChange({ id: id, name: name, type: tempOther[index].type });
    counter.current = 0;
  };

  useEffect(() => {
    // Conditionally preventing the page from being scrollable
    if (page !== "D") {
      // Store the original overflowY value
      const originalStyle = window.getComputedStyle(document.body).overflowY;
      // Prevent scrolling
      window.scrollTo(0, 0);
      document.body.style.overflowY = "hidden";

      // Cleanup function to reset the overflowY style when the component unmounts or the page changes
      return () => {
        document.body.style.overflowY = originalStyle;
      };
    }
  }, [page]);

  return (
    <div
      className={`${page == "D" && "p-1"} ${
        result == true
          ? "bg-green-500"
          : result == false
          ? "bg-red-700"
          : page != "D"
          ? "bg-green-500"
          : "bg-gray-100"
      } text-black min-h-screen`}
    >
      {page == "D" && (
        <div className="w-full fixed top-0 left-0 bg-slate-700 p-4 text-sm text-white navbay ">
          <div className="w-full flex justify-between">
            <div
              className="font-bold"
              onClick={() => {
                checkCode({ codes: "11608b2ffe1c77f2", name: "Random" });
              }}
            >
              {page == "D" ? "Database App" : "Database Scanner"}
            </div>

            <button onClick={connectWallet} className="connect-wallet-button">
              {wallet != "" ? tempWallet() : "Connect to Wallet"}
            </button>
          </div>
        </div>
      )}
      {page == "D" && (
        <div className="p-2 fixed bottom-0 right-0 w-full bg-slate-700 pb-4">
          <div className="w-full flex gap-2 justify-around">
            <button
              className="p-2 text-white w-full rounded-lg flex justify-center items-center "
              onClick={() => addModal("Add New", "Insert New to Database")}
            >
              <MdAdd size={30} color="white" />
            </button>
            <button
              className="p-2 text-white w-full rounded-lg flex justify-center items-center "
              onClick={() => (page == "D" ? setPage("R") : setPage("D"))}
            >
              <MdCamera size={30} color="white" />
            </button>
          </div>
        </div>
      )}
      {showModal && modalPlace()}

      {/* Display fetched data */}
      {page == "D" ? (
        <div className="lg:pt-12 pt-12 pb-36 overflow-y-scroll overflow-x-hidden h-screen">
          <div className=" overflow-y-scroll overflow-x-hidden">
            <div className="grid grid-cols-1 lg:flex lg:flex-wrap border-2 border-b-0 w-full ">
              {other?.map((a, index) => (
                <div
                  className="grid grid-cols-3  border-2 rounded-lg p-1 border-black w-full bg-gray-100 mb-1"
                  key={a.id}
                >
                  <div className="w-full font-bold flex border-r-2 p-4 ">
                    Name
                  </div>{" "}
                  <div className="w-full font-bold border-r-2 p-4 ">Type</div>
                  <div className=" w-full font-bold p-4 ">Modify</div>
                  <textarea
                    className={`w-full border-r-2 p-2 ${
                      a.complete == "true" && "bg-green-100"
                    }`}
                    value={a.name}
                    onChange={(e) => changeName(a, e.target.value)}
                  />
                  <textarea
                    className="w-full border-r-2 p-2 "
                    value={a.type}
                    onChange={(e) => changeType(a, e.target.value)}
                  />
                  <div className="flex flex-wrap justify-around w-full p-2 bg-white">
                    <button
                      className=" w-1/2"
                      onClick={changeItem}
                      disabled={loading && true}
                    >
                      <MdCheck
                        size={25}
                        className={` ${loading && "opacity-50"}`}
                      />
                    </button>
                    <button
                      className=" w-1/2"
                      onClick={() => completeItem(a)}
                      disabled={loading && true}
                    >
                      {a.complete == "false" || a.complete == false ? (
                        <MdCheckBox
                          size={25}
                          className={` ${loading && "opacity-50"}`}
                        />
                      ) : (
                        <MdClose
                          size={25}
                          className={` ${loading && "opacity-50"}`}
                        />
                      )}
                    </button>
                    <div className="border-b-2 w-full" />
                    <button
                      className=" w-1/2"
                      onClick={() => deleteItem(a)}
                      disabled={loading && true}
                    >
                      <MdDelete
                        size={25}
                        className={` ${loading && "opacity-50"}`}
                      />
                    </button>

                    {a.show ? (
                      <button
                        className=" w-1/2 "
                        onClick={() => {
                          let x = [...other];
                          x[index].show = false;
                          setOther(x);
                        }}
                        disabled={loading && true}
                      >
                        -
                      </button>
                    ) : (
                      <button
                        className=" w-1/2"
                        onClick={() => {
                          let x = [...other];
                          x[index].show = true;
                          setOther(x);
                        }}
                        disabled={loading && true}
                      >
                        <MdAdd size={25} />
                      </button>
                    )}
                  </div>
                  <div className="col-span-3  bg-gray-100">
                    <div className="flex flex-col ">
                      <div className=" w-full font-bold bg-gray-200 flex justify-center ">
                        Code
                      </div>
                      <div className="w-full flex justify-center ">
                        {a.codes}
                      </div>
                    </div>
                  </div>
                  {a.show && (
                    <div className="col-span-3 p-2 flex justify-center">
                      <QRCode value={a.codes} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${
            result == true
              ? "bg-green-500"
              : result == false
              ? "bg-red-500"
              : "bg-gray-900"
          } text-white min-h-screen w-full `}
        >
          <div className="flex flex-col h-screen">
            {result == true || result == false ? (
              ""
            ) : (
              <QrScanner
                onResult={(text, result) => checkCode({ codes: text })}
                onError={(error) => console.log(error)}
              />
            )}

            <div
              className={`flex-grow ${
                result == true
                  ? "bg-green-500"
                  : result == false
                  ? "bg-red-500"
                  : "bg-green-700"
              } relative h-full`}
            >
              <div className="flex justify-center items-center h-full">
                {result != true && result != false ? (
                  "Ready to Scan" // This is the text that needs to be centered
                ) : result == true ? (
                  <div className="w-full flex flex-col justify-center items-center">
                    <div className="text-3xl font-bold">Success</div>
                    <div className="text-white flex gap-1">
                      <div className="font-bold text-2xl">Name:</div>
                      <div className="text-2xl">
                        {tempData.name.toUpperCase()}
                      </div>
                    </div>
                    <div className="text-white flex gap-1">
                      <div className="font-bold text-2xl">Type:</div>
                      <div className="text-2xl">
                        {tempData.type.toUpperCase()}
                      </div>
                    </div>
                  </div>
                ) : (
                  result == false && (
                    <div className="text-3xl font-bold">Not Valid</div>
                  )
                )}
              </div>
            </div>
            <div
              className={`mt-auto pb-8 flex justify-center items-center ${
                result == true
                  ? "bg-green-500"
                  : result == false
                  ? "bg-red-500"
                  : page != "D"
                  ? "bg-green-700"
                  : "bg-gray-100"
              }`}
            >
              <button
                onClick={() => {
                  if (result == true || result == false) {
                    setTempData({ name: "", type: "" });
                    setResult(null);
                  } else {
                    setPage("D");
                    setTempData({ name: "", type: "" });
                    setResult(null);
                  }
                }}
              >
                {result != true && result != false ? (
                  <MdClose size={30} />
                ) : (
                  <MdArrowForward size={30} />
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-2 p-4">
        {mintData
          .filter(
            (a) =>
              a.offChainData != null &&
              a.onChainData.updateAuthority ==
                "FCNAWFRkJMFFBBz9hyoQgk8yEy1SHVTmn1rHnmuSPhxQ"
          )

          .map((a) => (
            <>
              <div className="w-full border-4 rounded-lg p-4 ">
                <img src="https://fightcapital.io/images/FC/955.png" />
                <div className="w-full flex justify-center items-center">
                  <QRCode value={a.onChainData.mint} />
                </div>
                <div className="text-center font-bold">
                  {a.offChainData.name}
                </div>
                <div className="text-center text-sm">
                  {a.offChainData.description}
                </div>
                <div className="text-center text-sm flex flex-wrap">
                  <div className="w-full break-words overflow-wrap">
                    {a.onChainData.updateAuthority}
                  </div>
                </div>
              </div>
            </>
          ))}
      </div>
    </div>
  );
}

export default App;
